@tailwind base;
@tailwind components;
@tailwind utilities;

body { height: 100dvh; }

@font-face {
    font-family: 'Brushtip Travis';
    src: local('Brushtip Travis'), url(./btrav_15-webfont.woff) format('woff');
}

.brushtip-travis {
    font-family: "Brushtip Travis";
    font-style: normal;
}

